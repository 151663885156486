@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Poppins:wght@300;400;500;600;700&display=swap');
$font-primary :'Poppins',
sans-serif;
$font-secondary: 'Montserrat',
sans-serif;
$yellow: #FFA30A;
$dirty-white:#FAF2E9;
$blue: #003149;
$black: #1F1F1F;

.center {
    text-align: center;
}