@use 'vars' as *;
body {
    font-family: $font-primary;
    font-size: 16px;
    color: $black;
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}

img {
    height: auto !important;
}

.clear-float {
    clear: both;
}

.highlight {
    color: #bbbbbb;
    font-size: 40px;
}

.font-light {
    font-weight: 300;
}

.font-semibold {
    font-weight: 600;
}

.font-size-16 {
    font-size: 16px;
}

.button { /* used for pager buttons */
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    padding: 10px 20px;
    max-width: 100%;
    color: #FFF;
    background-color: grey;
    border: 1px solid grey;
    box-sizing: border-box;
    transition: all 0.3s ease;
}

.button:hover {
    color: grey;
    background-color: transparent;
}

img.display-left {
    float: left;
    max-width: 50%;
    margin-right: 20px;
    margin-bottom: 10px;
}

img.display-right {
    float: right;
    max-width: 50%;
    margin-left: 20px;
    margin-bottom: 10px;
}

.btn-yellow {
    display: inline-block;
    border-radius: 25px;
    background-color: $yellow;
    color: $blue;
    font-weight: bold;
    padding: .75rem 2rem;
    border: none;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    transition: all .25s ease-in-out;
    &:hover {
        color: #FFF;
        filter: brightness(1.1);
    }
}

.btn-white-outline {
    display: inline-block;
    border-radius: 25px;
    background-color: transparent;
    color: #fff;
    font-weight: bold;
    padding: .75rem 2rem;
    border: 1px solid #ffffff;
    font-size: 16px;
    cursor: pointer;
    transition: all .25s ease-in-out;
    &:hover {
        color: $blue;
        background-color: $yellow;
        border-color: $yellow;
    }
}

.stars-3 {
    content: url("../assets/3stars.svg");
}

.stars-4 {
    content: url("../assets/4stars.svg");
}

.stars-5 {
    content: url("../assets/5stars.svg");
}

.stars-3,
.stars-4,
.stars-5 {
    height: auto;
    width: 10vw;
    min-width: 100px;
    padding-left: 1rem;
}

.text-blue-bg {
    background-color: $blue;
    padding: 5px 1rem;
    color: white;
    font-style: italic;
    display: inline-block;
    font-family: $font-secondary;
    margin: 5px 0;
}

.two-col {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.three-col {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

@media only screen and (max-width: 760px) {
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px 0;
    }
}